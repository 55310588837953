$(document).ready(function () {
    initScroll();
    initMainVideo();
    initSliders();
    initNewsletter();
    initNoVideo();
    initBookButton();
    checkOrientation();
    orientationChange();
    initImageShadow();
    // customCookieBar();
})

inlineSVGs(projectSettings.inlineSVG);

function checkOrientation() {
    if( $('body.no-video').length < 1 ) {
        if($(window).innerHeight() > $(window).innerWidth()){
            $('.video__wrap-mobile').addClass('on');
            $('.video__wrap-desktop').removeClass('on');
        } else {
            $('.video__wrap-mobile').removeClass('on');
            $('.video__wrap-desktop').addClass('on');
        }
    }
}

function initImageShadow() {
    if ( $('.image-text').length > 0 ) {
        var imgHeight = $('.image-text__image img').height();
        $('.image-text__image-wrap-container-shadow').css('max-height', imgHeight);
    }
}

function orientationChange() {
    $(window).on('resize', function() {
        checkOrientation();
        initImageShadow();
    })
}

function initNewsletter() {
    $('a[href="#newsletter"]').on('click', function() {
        $('#newsletter').foundation('open');
    })
}

function initNoVideo() {
    if ( $('body.no-video').length > 0 ) {
        $('.header').addClass('video-complete');
        $('.menu').addClass('video-complete');
        $('.contact').addClass('video-complete');
    }
}

var initScroll = function () {

	$('.scroll').on('click', function () {

		$('html, body').animate({
			scrollTop: $( '.video' ).outerHeight() - 25
		}, 750);
	});

}

function initMainVideo() {
    if( $('body.no-video').length < 1 ) {
        if ( $(window).outerWidth() >= 640 ) {
            var videoItem = $('.video__wrap-desktop video')[0];
            videoItem.addEventListener("timeupdate", function() {
                if ( videoItem.currentTime >= 7.9) {
                    videoItem.currentTime = 3.7
                    videoItem.play();
                }
            }, false);
        } else {
            var videoItem = $('.video__wrap-mobile video')[0];
            videoItem.addEventListener("timeupdate", function() {
                if ( videoItem.currentTime >= 7.9) {
                    videoItem.currentTime = 3.7
                    videoItem.play();
                }
            }, false);
        }
        
        setTimeout(function() {
            $('.header').addClass('video-complete');
            $('.menu').addClass('video-complete');
            $('.contact').addClass('video-complete');
            $('.video__bottom').addClass('video-complete');
        }, 1500);
    }
}

function initSliders() {
    $('.slider__slider').slick({
        arrows: true,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        responsive: [
            {
                breakpoint: 640,
                settings: {
                    arrows: false
                }
            }
        ]
    })
}

function initBookButton() {
    if( $('body.no-video').length < 1 ) {
        if ( $(window).outerHeight() > $(window).outerWidth() ) {
            var startPoint = $('.image-text').offset().top;
        } else {
            var startPoint = $('.video').offset().top;
        }
        
        $(window).on('scroll', function() {
            var y_scroll_pos = window.pageYOffset;
            var scroll_pos_test = startPoint;

            if ( $(window).outerHeight() > $(window).outerWidth() ) {
                if((y_scroll_pos - 200) > scroll_pos_test) {
                    $('.book').addClass('show');
                } else {
                    $('.book').removeClass('show');
                }
            } else {
                if((y_scroll_pos - 90) > scroll_pos_test) {
                    $('.book').addClass('show');
                } else {
                    $('.book').removeClass('show');
                }
            }
        });
    }
}

// function customCookieBar() {
// 	console.log("THIS IS HITTING COOKIE BAR");

// 	if ( $('.seed-library__cookie-bar').length > 0 ) {
// 		if ( !Cookies.get('accepted_cookies') ) {
// 			$('.seed-library__cookie-bar').fadeIn();
// 		}

//         $('.off-canvas-events__close').on('click', function () {
// 			Cookies.set('accepted_cookies', 'true', {expires: $('.seed-library__cookie-bar').data('notice-keep-hidden-for-n-days')});
// 			$('.seed-library__cookie-bar').css('display', 'none')
// 		});


// 	}
// }